@media only screen and (max-width: 1224px) and (orientation: portrait) {

    /* For mobile phones: */
    .homepageLink-container {
        width:60% !important;
    }

    .colHomepageLink{
        padding:1rem 1rem
    }
}

@media only screen and (max-width: 1224px) and (orientation: landscape) {

    /* For mobile phones: */
    .homepageLink-container {
        width:100% !important;
        height:100% !important;
    }

    .colHomepageLink{
        padding:1rem 1rem
    }
}

.homepageLink-container {
    width:15vw;
    height:35vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border:2px solid black;
    border-radius: 10px;
    background-color: #0D7377;
}
.homepageLink-container:hover {
    background-color: #0a5154;
    text-decoration: none;
}

.colHomepageLink{
 padding: 1rem;
}

.homepageLink-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:1rem;
}

.homepageLink-text{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
    color:white;
    text-decoration: none;
    text-align: center;
}


.navlink{
    color:white;
    text-decoration: none;
    display:block;
    width:12rem;
}

.navlink:hover{
    text-decoration: none;
}



